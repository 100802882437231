@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');



.nav-bar{
    background: linear-gradient(-90deg, #111111 0%, #222222 100%);
    height: 56px;
    padding-left: 35px;
    padding-right: 35px;
}

.nav-bar-mobile{
    background: linear-gradient(-90deg, #111111 0%, #222222 100%);
}

.nav-bar-txt-1{
    font-family: RfDewi-Expanded;
    font-weight: bold;
    font-size: 15px;
}

.nav-bar-txt-2{
    font-family: RfDewi-Expanded;
    font-weight: 300;
    font-size: 12px;
    align-self: flex-end;
    transform: translateY(-1px);
}

.outline-icon{
    color: transparent;
    border: white 1px solid;
}


