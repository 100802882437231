@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');


.announcement-container {
    position: relative;
    overflow: hidden;
    min-height: 28px;
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
  }
  
  .announcement-content,
  .ratings {
    position: absolute;
    transition: opacity 1s ease-in-out;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Show and hide classes to control visibility */
  .show {
    opacity: 1;
    z-index: 1;
  }
  
  .hide {
    opacity: 0;
    z-index: 0;
  }
  
  .announcement-text {
    font-size: 14px;
    font-weight: bold;
    font-family: 'RfDewi-Expanded', sans-serif;
  }