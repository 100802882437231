/* Expanded Fonts */
@font-face {
    font-family: 'RFDewi-Expanded';
    src: url('../public/fonts/RFDewiExpanded-Ultralight.woff2') format('woff2');
    font-weight: 200; /* Use numeric values */
    font-style: normal;
  }
  @font-face {
    font-family: 'RFDewi-Expanded';
    src: url('../public/fonts/RFDewiExpanded-Ultrabold.woff2') format('woff2');
    font-weight: 800; /* Use numeric values */
    font-style: normal;
  }
  @font-face {
    font-family: 'RFDewi-Expanded';
    src: url('../public/fonts/RFDewiExpanded-Thin.woff2') format('woff2');
    font-weight: 100; /* Use numeric values */
    font-style: normal;
  }
  @font-face {
    font-family: 'RFDewi-Expanded';
    src: url('../public/fonts/RFDewiExpanded-Semibold.woff2') format('woff2');
    font-weight: 600; /* Use numeric values */
    font-style: normal;
  }
  @font-face {
    font-family: 'RFDewi-Expanded';
    src: url('../public/fonts/RFDewiExpanded-Regular.woff2') format('woff2');
    font-weight: 400; /* Use numeric values */
    font-style: normal;
  }
  @font-face {
    font-family: 'RFDewi-Expanded';
    src: url('../public/fonts/RFDewiExpanded-Light.woff2') format('woff2');
    font-weight: 300; /* Use numeric values */
    font-style: normal;
  }
  @font-face {
    font-family: 'RFDewi-Expanded';
    src: url('../public/fonts/RFDewiExpanded-Bold.woff2') format('woff2');
    font-weight: 700; /* Use numeric values */
    font-style: normal;
  }
  @font-face {
    font-family: 'RFDewi-Expanded';
    src: url('../public/fonts/RFDewiExpanded-Black.woff2') format('woff2');
    font-weight: 900; /* Use numeric values */
    font-style: normal;
  }
  
  /* Extended Fonts */
  @font-face {
    font-family: 'RFDewi-Extended';
    src: url('../public/fonts/RFDewiExtended-Ultralight.woff2') format('woff2');
    font-weight: 200; /* Use numeric values */
    font-style: normal;
  }
  @font-face {
    font-family: 'RFDewi-Extended';
    src: url('../public/fonts/RFDewiExtended-Ultrabold.woff2') format('woff2');
    font-weight: 800; /* Use numeric values */
    font-style: normal;
  }
  @font-face {
    font-family: 'RFDewi-Extended';
    src: url('../public/fonts/RFDewiExtended-Thin.woff2') format('woff2');
    font-weight: 100; /* Use numeric values */
    font-style: normal;
  }
  @font-face {
    font-family: 'RFDewi-Extended';
    src: url('../public/fonts/RFDewiExtended-Semibold.woff2') format('woff2');
    font-weight: 600; /* Use numeric values */
    font-style: normal;
  }
  @font-face {
    font-family: 'RFDewi-Extended';
    src: url('../public/fonts/RFDewiExtended-Regular.woff2') format('woff2');
    font-weight: 400; /* Use numeric values */
    font-style: normal;
  }
  @font-face {
    font-family: 'RFDewi-Extended';
    src: url('../public/fonts/RFDewiExtended-Light.woff2') format('woff2');
    font-weight: 300; /* Use numeric values */
    font-style: normal;
  }
  @font-face {
    font-family: 'RFDewi-Extended';
    src: url('../public/fonts/RFDewiExtended-Bold.woff2') format('woff2');
    font-weight: 700; /* Use numeric values */
    font-style: normal;
  }
  @font-face {
    font-family: 'RFDewi-Extended';
    src: url('../public/fonts/RFDewiExtended-Black.woff2') format('woff2');
    font-weight: 900; /* Use numeric values */
    font-style: normal;
  }

  @font-face {
    font-family: 'F1';
    src: url('../public/fonts/Formula1-Black.woff2') format('woff2');
    font-style: normal;
  }
  


  