@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


.carousel-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.carousel-images {
    display: flex;
    transition: transform 0.5s ease-in-out;
    height: 100%;
    width: 100%;
}
.carousel-image {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}
.overlay-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.hero-section-about{
    background: linear-gradient(-90deg, #111111df 0%, #222222dd 100%);
}


.best-seller-item{
    background: linear-gradient(-90deg, #111111 0%, #222222 100%);
}

.collection-item{
    background-position: center;
   background-size: cover;
}

.footer{
    background: linear-gradient(-90deg, #111111 0%, #222222 100%);
}
