@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');


.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.973);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loader {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    width: 100px;
}

.ball {
    width: 20px;
    height: 20px;
    background-color: #000000;
    border-radius: 50%;
    animation: bounce 0.6s infinite alternate;
}

.red{
    background-color: #ff0000;

}

@keyframes bounce {
    to {
        transform: translateY(-100%);
    }
}
